var parallax = (function() {

  const homepageIntro = document.querySelector("#introduction-copy");
  const desktopMenuItems_top = document.querySelectorAll("#desktop-menu_top ul li");
  const projects = document.querySelectorAll(".project");
  const body = document.querySelector("body");

  var passHomepageIntroduction = new Waypoint({
    element: document.querySelector("body"),
    handler: function(direction) { 
      if(direction == "down") { 
        homepageIntro.classList.remove("active");
      } else {
        homepageIntro.classList.add("active");
      }
    },
    offset: -100
  });

  var passIntroduction = new Waypoint({
    element: document.querySelector("#introduction"),
    handler: function(direction) { 
      if(direction == "down") {
        body.classList.add("affix");  
      } else {
        body.classList.remove("affix");  
      }
    },
    offset: - document.querySelector("#introduction").offsetHeight + 300
  });

  projects.forEach((item, index) => {
    new Waypoint({
      element: item,
      handler: function(direction) {
        if(direction == "down") {
          item.classList.add("active");
        }
      },
      offset: 300
    });
  });

  
  setTimeout(function() {
    publicFunctions.animateChildrenIn(desktopMenuItems_top, "visible", 100);
  }, 1200);
  setTimeout(function() {
    homepageIntro.classList.add("active");
  }, 500);
  
  setTimeout(function() {
    document.querySelector("#contact-button").classList.add("active");
  }, 1600);  

}());