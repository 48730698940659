var global = (function() {
    // Testimonials Carousel
    let arrows = document.querySelector(".testimonial-arrows");
    if (arrows !== null) {
        if (getCarousel().childElementCount > 2) { // If there are more than two testimonials, display the carousel arrows
            arrows.classList.remove("d-md-none");
        }
        checkForArrows();
    };

    function checkForArrows() { // Run the script if the arrows are visible
        if (window.getComputedStyle(arrows).display !== "none") {
            initalizeCarousel();
        };
        window.addEventListener('resize', checkForArrows);
    }

    function initalizeCarousel() {
        console.log("initialize");
        const forward_arrow = document.querySelector("#testimonial-arrow-forward");
        const back_arrow = document.querySelector("#testimonial-arrow-back");

        forward_arrow.addEventListener('click', moveCarouselForward);
        back_arrow.addEventListener('click', moveCarouselBackward);
    }

    function getCarousel() {
        return document.getElementById("testimonial-items"); // The row containing testimonial columns
    }

    function moveCarouselForward() {
        var carousel_items = getCarousel();
        var shifted_node = carousel_items.removeChild(carousel_items.children[0]); // Remove first testimonial
        carousel_items.appendChild(shifted_node); // Move to end
    }

    function moveCarouselBackward() {
        var carousel_items = getCarousel();
        var shifted_node = carousel_items.removeChild(carousel_items.children[(carousel_items.childElementCount - 1)]); // Remove last testimonial
        carousel_items.insertBefore(shifted_node, carousel_items.children[0]); // Insert before first testimonial
    }
}());
