var publicFunctions = (function() {
  'use strict';



  /* =================== public methods ================== */

  const global_function = (param)  => {
    console.log("Returns value: " + param);
  };

  /* Add class to children 1 by 1
    * Use querySelectorAll to gather object
    * Use CSS animations where possible
    * object = selector
    * className = class name to be added
    * speed = speed
  */
  const animateChildrenIn = (object, className, speed) => {
    object.forEach((item, index) => {
      setTimeout(() => {
        item.classList.add(className);
      }, speed * index);
    });
  };

  /* Add class to children 1 by 1
    * Use querySelectorAll to gather object
    * Use CSS animations where possible
    * object = selector
    * className = class name to be added
    * speed = speed
  */

  const animateChildrenOut = (object, className, speed) => {
    object.forEach((item, index) => {
      setTimeout(() => {
        item.classList.remove(className);
      }, speed * index);
    });
  };

  /* =============== export public methods =============== */
  return {
    global_function: global_function,
    animateChildrenIn: animateChildrenIn,
    animateChildrenOut: animateChildrenOut
  };
  
}());
