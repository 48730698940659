var navigation = (function() {

  const masthead = document.querySelector("#masthead");
  const navToggle = document.querySelector("#hamburger");
  
  const mobileMenu = document.querySelector("#mobile-menu");
  const body = document.querySelector("body");

  

  navToggle.onclick = () => {
    mobileMenu.classList.toggle("open");
    body.classList.toggle("menu-open");
  };
  
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });
 
  

}()); 